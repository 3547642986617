.errorBoundaryCard {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 6px;
    margin-top: 4.5rem;
    width: 100%;
}