// Variables

//
// Color system
//

$white-two: #bbb;
$white-three: #d8d8d8;
$white-four: #fafafa;
$white-five: #eee;

$bright-orange: #ff6b00;
$dusty-orange: #ef6937;

$warm-grey: #9b9b9b;

$greyish-brown: #4a4a4a;

$warm-grey-two: #979797;

$greenblue: #21d394;
$green: #17cb44;

$success: #21d394;
$danger: #ed457a;

//
//* Label colors
//

$loyal: #143dda;
$spender: #4072ca;
$regular: #ef6a37;
$preferer: #63c9a3;
$newbie: #000000;
$initiated: #f8bf45;

//
// Font system
//

$HelveticaNeue: "Helvetica Neue", "sans-serif", arial;
