// *****************************************************
// **** Media quaries for screens less then 1024px ****
// *****************************************************

@media (max-width: 500px) {

    // ==== Media query for min with 768px ====
    // ========= Left Sidebar Section =========
    // ========================================

    .left-sidebar{
        // position: static;
        display: block;
        overflow-y: auto;
        width: 100%;
        max-width: 100%;
        box-shadow: none;
        padding: 0px;
        background: #fff;
    
        &__nav{
            padding: 2rem 3rem;
            display: flex;
            width: 100%;
            position: relative;
            justify-content: space-between;
            align-items: center;
    
            &__toggle{
                display: flex;
                padding: .2rem;
                height: 3.2rem;
                width: auto;
                background: transparent;
                outline: none;

                img{
                    height: 100%;
                    width: auto
                }
            }
    
            &__brand-sm{
                display: flex;
                padding: .2rem;
                height: 3.5rem;
                width: auto;

                img{
                    height: 100%;
                    width: auto;
                }
            }
    
            &__user{
                display: flex;
                padding: .2rem;

                img{
                    height: 100%;
                    width: 100%;
                }
            }
    
            &::after{
                content: '';
                position: absolute;
                bottom: 0px;
                left: 3.75rem;
                right: 3.75rem;
                border-bottom: 1px solid #eeeeee;
            }
            
            &__brand-lg{
                display: none !important;
                // width: 100%;
                // position: relative;
                // flex-shrink: 0;
        
                // img{
                //     width: 100%;
                //     height: auto;
                //     flex-shrink: 0;
                // }
                
            }
        }

        &__menu{
            height: 0px !important;
            padding: 0px !important;
        }
    
    
        // &__menu{
        //     padding: 3rem 0px;
        //     &__list{
        //         display: flex;
        //         flex-direction: column;
    
        //         &--level-2{
        //             display: flex;
        //             flex-direction: column;
        //             // height: 0px;
        //             // opacity: 0;
        //         }
    
        //     }
    
        //     &__item{
        //         display: block;
        //         width: 100%;
    
        //         &--level-2{
        //             display: block;
        //             width: 100%;
        //         }
        //     }
    
        //     &__link{
        //         display: flex;
        //         width: 100%;
        //         padding: 1.5rem 3.75rem;
        //         font-size: 1.5rem;
        //         font-weight: bold;
        //         font-style: normal;
        //         font-stretch: normal;
        //         line-height: normal;
        //         letter-spacing: normal;
        //         color: $white-three;
        //         font-family: $HelveticaNeue;
        //         align-items: center;
        //         height: auto;
        //         position: relative;
    
        //         &:hover{
        //             color: $white-three;
    
        //             &::after{
        //                 transform: scale(1);
        //             }
        //         }
                
        //         &::after{
        //             position: absolute;
        //             content: "";
        //             height: 100%;
        //             transform: scale(0);
        //             right: 0px;
        //             border-right: 4px solid #000;
        //             top: 0px;
        //             transition: transform .2s;
        //         }
    
        //         &--level-2{
        //             display: flex;
        //             width: 100%;
        //             padding: 1.5rem 3.75rem;
        //             padding-left: 8rem;
        //             font-size: 1.5rem;
        //             font-weight: bold;
        //             font-style: normal;
        //             font-stretch: normal;
        //             line-height: normal;
        //             letter-spacing: normal;
        //             color: $white-three;
        //             font-family: $HelveticaNeue;
        //             align-items: center;
    
        //             &:hover{
        //                 color: $white-three;
        //             }
    
        //             svg{
        //                 margin-right: 1.5rem;
        //                 height: 1.8rem !important;
        //                 width: 1.8rem !important;
        //                 flex-shrink: 0;
        //             }
    
        //             &--active{
        //                 color: $dusty-orange !important;
        //                 position: relative;
    
        //                 span{
        //                     color: $dusty-orange !important; 
        //                 }
        
        //                 svg{
        
        //                     path{
        //                         fill: $dusty-orange;
        //                     }
        
        //                     g{
        //                         fill: $dusty-orange;
        //                     }
        
        //                     rect{
        //                         fill: $dusty-orange;
        //                     }
        //                 }
        //             }
        //         }
                
                
        //         svg{
        //             display: block;
        //             margin-right: 1.5rem;
        //             height: 1.8rem;
        //             width: 1.8rem;
        //             flex-shrink: 0;
        //         }
    
        //         &--active{
        //             background: rgba($dusty-orange, 0.05);
        //             color: #000;
        //             position: relative;
    
        //             &:hover{
        //                 color: #000;
        //             }
    
        //             &::after{
        //                 position: absolute;
        //                 content: "";
        //                 height: 100%;
        //                 transform: scale(1);
        //                 right: 0px;
        //                 border-right: 4px solid $dusty-orange;
        //                 top: 0px;
        //             }
    
        //             svg{
    
        //                 path{
        //                     fill: #000;
        //                 }
    
        //                 g{
        //                     fill: #000;
        //                 }
    
        //                 rect{
        //                     fill: #000;
        //                 }
        //             }
        //         }
        //     }
        // }
    }
    
}