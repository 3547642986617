.ant-table-filter-dropdown {
    border-radius: 6px !important;
    .ant-table-filter-dropdown-btns{
        border-radius: 0 0 6px 6px !important;
        .ant-btn{
            border-radius: 6px !important;  
        }
    }
    .ant-dropdown-menu {
        width: 200px !important;
      }



}

.ant-table-cell{
    user-select: auto !important;
}

.ant-table-filter-dropdown-search-input{
    border-radius: 6px !important;
}

  .ant-table-filter-dropdown .ant-checkbox-wrapper + span, .ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
    white-space: break-spaces;
}

.ant-table-thead > tr > th {
    font-weight: bold;
    @media only screen and (max-width: 480px){
        font-size: 12px;
    }
}

.ant-table-pagination-right{
    margin-right: 2rem !important;
}

.ant-checkbox,.ant-checkbox-checked, .ant-checkbox-input, .ant-checkbox-inner{
    border-radius: 6px !important;
     &:hover{
    border-radius: 6px !important;

     }
}

.ant-pagination {
    >li, button{
        border-radius: 6px !important;
    }
}

.anticon {
	vertical-align: text-bottom !important;
}

.ant-table-summary{
    .ant-table-cell{
        &::before{
            position: absolute;
            top: 50%;
            right: 0;
            width: 1px;
            height: 1.6em;
            background-color: rgba(0, 0, 0, 0.06);
            transform: translateY(-50%);
            transition: background-color 0.3s;
            content: '';
        }
    }
}

.ant-table-thead{
    .ant-table-cell{
        border-radius: 6px !important;
    }
}


.ant-table-tbody > tr.ant-table-row:hover > td {
    background: #fff !important; 
}

@media only screen and (max-width: 480px){
    .ant-table-tbody > tr.ant-table-row > td {
        background: #fff !important;     
        font-size: 12px;
    }
}

.ant-table-tbody > tr.ant-table-row:hover > td.ant-table-column-sort {
    background: #fff !important; 
}

.ant-table-cell-row-hover {
    background: #fff !important;
}

td.ant-table-column-sort {
    background: #fff !important;
}


.ant-table-thead > tr > th {
    font-weight: bold !important;
}

.ant-table-filter-dropdown .ant-dropdown-menu {
    width: 200px !important;
  }

  .ant-table-filter-dropdown .ant-checkbox-wrapper + span, .ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
    white-space: break-spaces;
    word-break: break-all;
}

.ant-picker-time-panel-column{
    &::after{
      height: unset !important;
    }
  }

  .ant-table-thead th.ant-table-column-sort {
    background: #fafafa !important;
    &:hover{
    background: #fafafa !important;
    }
}

.ant-tooltip-inner {
    font-size: 1.2rem;
    font-family: "Helvetica Neue", "sans-serif", arial;
    font-weight: 300 !important;
    border-radius: 0.6rem;
    line-height: 1.4;
    color: #fff;
    text-align: left !important;
}

.ant-table-summary{
    .ant-table-cell-fix-left{
        left: 0px;
    }
}
// .ant-btn-primary{
//     border-radius: 6px !important;
// }

// .ant-picker-panel-container{
//     border-radius: 6px !important;
// }
// .ant-picker-panel, .ant-picker-time-panel{
//     border-radius: 6px !important;

// }
