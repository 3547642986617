// Base CSS Starts
@import "./variables";

* {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0px;
	margin: 0px;
}

*,
:after,
:before {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

html {
	line-height: 1.15;
	font-size: 62.5%;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-ms-overflow-style: scrollbar;
	scroll-behavior: smooth;
	-webkit-tap-highlight-color: rgba(18, 38, 63, 0);
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	user-select: text !important;
}

@-ms-viewport {
	width: device-width;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
	display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: inherit;
	font-weight: 500;
	line-height: 1.1;
	margin-bottom: 0px;
	color: inherit;
}

body {
	font-family: $HelveticaNeue !important;
	font-weight: normal;
	line-height: 1.25;
	font-size: 1.8rem;
	margin: 0;
	text-align: left;
	color: $greyish-brown;
	// background-color: $white-four;
	// background: $white-four;
	background: #fafafa !important;
}

[tabindex="-1"]:focus {
	outline: 0 !important;
}

button,
input,
textarea {
	border: none;
	outline: none !important;
}

p {
	margin-top: 0;
	margin-bottom: 1rem;
}

a:hover,
a:active {
	text-decoration: none;
}

ul {
	list-style: none;
	margin: 0px;
}

img {
	border-style: none;
}

img,
svg {
	vertical-align: middle;
}

svg {
	overflow: hidden;
}

table {
	border-collapse: collapse;
}

// **************************************
// *       Main CSS Starts
// **************************************

.body-wrapper {
	height: 100%;
	width: 100%;
	position: relative;
}

.container-fluid {
	width: 100%;
	padding-right: 20px;
	padding-left: 20px;
	margin-right: auto;
	margin-left: auto;
}

.row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -1.5rem;
	margin-left: -1.5rem;
}

.col-2 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding-right: 1.5rem;
	padding-left: 1.5rem;
	-webkit-box-flex: 1;
	-ms-flex: 1 0 50%;
	flex: 1 0 50%;
	max-width: 50%;
}

.col-3 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding-right: 1.5rem;
	padding-left: 1.5rem;
	-webkit-box-flex: 1;
	-ms-flex: 1 0 33.33%;
	flex: 1 0 33.33%;
	max-width: 33.33%;
}

.no-bg {
	background: transparent !important;
}

// **************************************
// *         Content Page Section
// **************************************

.content-page {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin-left: 28rem;

	.page-header {
		padding: 5rem 4rem;
	}
}


// **************************************
// *         Tracker Pages
// **************************************

.col-tracker {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding-right: 1.5rem;
	padding-left: 1.5rem;
	-webkit-box-flex: 1;
	-ms-flex: 1 0 33.33%;
	flex: 1 0 33.33%;
	max-width: 33.33%;
}

.chart-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 3.5rem;
	@media (max-width: 480px) {
		margin-bottom: 1.0rem;
	}

	&__title {
		font-size: 1.6rem;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: $greyish-brown;
	}

	&__options {}

	&__button {
		font-size: 1.2rem;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		border-radius: 4px;
		background-color: #fff;
		padding: 0.6rem 1rem;
		color: $greyish-brown;
		border: 1px solid $white-two;

		&--active {
			border: 1px solid $white-five;
			color: $greyish-brown;
			background-color: $white-five;
		}

		&:not(:last-of-type) {
			margin-right: 1.5rem;
		}
	}
}

// **************************************
// *         Chart header
// **************************************


// *****************************************************
// ********************* Media quaries *****************
// *****************************************************

@media (max-width: 1024px) {
	.col-2 {
		-ms-flex: 1 0 100%;
		-webkit-box-flex: 1;
		flex: 1 0 100%;
		max-width: 100%;
	}
}


@media (min-width: 2559px) {

	.col-tracker {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
		-webkit-box-flex: 1;
		-ms-flex: 1 0 25%;
		flex: 1 0 25%;
		max-width: 25%;
	}

}


@media (max-width: 1300px) and (min-width: 851px) {

	.col-tracker {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
		-webkit-box-flex: 1;
		-ms-flex: 1 0 50%;
		flex: 1 0 50%;
		max-width: 50%;
	}

}

@media (max-width: 850px) {

	.col-tracker {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
		-webkit-box-flex: 1;
		-ms-flex: 1 0 100%;
		flex: 1 0 100%;
		max-width: 100%;
	}

}