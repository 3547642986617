// Font : Helvetica Neue, Type : HelveticaNeue, Weight : normal, style : normal
@font-face {
    font-family: 'Helvetica Neue';
    src: url("../fonts/HelveticaNeue-Light.woff2") format("woff"), url("../fonts/HelveticaNeue-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

// Font : Helvetica Neue, Type : HelveticaNeue, Weight : normal, style : normal
@font-face {
    font-family: 'Helvetica Neue';
    src: url("../fonts/HelveticaNeue-Roman.woff2") format("woff"), url("../fonts/HelveticaNeue-Roman.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

// Font : Helvetica Neue, Type : HelveticaNeue-Bold, Weight : bold, style : normal
@font-face {
    font-family: 'Helvetica Neue';
    src: url("../fonts/HelveticaNeue-Bold.woff") format("woff"), url("../fonts/HelveticaNeue-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}